import React from "react"
import Header from "../components/header"
import Footer from "../components/footer"
import Carousel from "../components/carouselJobs"
import BoxStellenangebot from "../components/boxStellenangebot"
import BoxAusbildung from "../components/boxAusbildung"
import "../styles/jobs.css"

export default () => (
  <div>
    <Header />
    <Carousel />
    <BoxStellenangebot />
    <div className="arrow-up red" />
    <div className="contentbox red" />
    <div className="arrow-down red" />
    <BoxAusbildung />
    <div className="arrow-up black" />
    <div className="contentbox black foot fit" />
    <Footer />
  </div>
)
