import React from "react"
import { Container, Row, Col, Image } from "react-bootstrap"
import { FaEnvelope, FaPhone } from "react-icons/fa"
import LightSpeed from "react-reveal/LightSpeed"
import Fade from "react-reveal/Fade"
import "../styles/jobs.css"

import imgStellenangebot01 from "../assets/stellenangebot.jpg"

export default () => (
  <Container fluid>
    <Row>
      <Col xs={12} sm={8} className="contentbox contact white text-center">
        <LightSpeed left>
          <div>
            <h1>Wir suchen Dachprofis!</h1>
            <h4>Werden Sie Teil unseres Teams</h4>
            <hr />
            <div className="jobs-wrapper">
              <div className="jobs-container">
                <h4>Du hast:</h4>
                <ul className="jobs-list">
                  <li>Abgeschlossene Ausbildung zum Dachdecker*in</li>
                  <li>Teamgeist</li>
                  <li>Selbstständiges Arbeiten</li>
                  <li>Führerschein Klasse B</li>
                </ul>
                <p />
                <h4>Wir bieten:</h4>
                <ul className="jobs-list">
                  <li>Leistungsgerechte Zahlung</li>
                  <li>
                    Keine Montage, wir arbeiten meistens im Umkreis von 40 km
                  </li>
                  <li>Arbeit am Samstag wird nach Möglichkeit vermieden</li>
                  <li>Moderate Probezeit (6 Monate)</li>
                  <li>
                    Fortbildungsmöglichkeiten zum Vorarbeiter und Meister (wir
                    unterstützen Sie)
                  </li>
                </ul>
                <p />
                <h4>Wir freuen uns auf Ihre Bewerbung:</h4>
                Dachdeckerei Michael Bürgel e.K. <br />
                Inhaber Miro Graf
                <br />
                Kruppstr .14
                <br />
                41540 Dormagen <br />
                <a className="black-link" href="tel:0049213363294">
                  <FaPhone className="mirror" /> 02133 / 63294
                </a>
                <br />
                <a
                  className="red-link"
                  href="mailto:jobs@dachdeckerei-buergel.de"
                >
                  <FaEnvelope /> jobs@dachdeckerei-buergel.de
                </a>
              </div>
            </div>
          </div>
        </LightSpeed>
      </Col>
      <Col xs={12} sm={4} className="contentbox contact gray text-center">
        <Fade right>
          <Image src={imgStellenangebot01} fluid />
        </Fade>
      </Col>
    </Row>
  </Container>
)
