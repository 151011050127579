import React from "react"
import { Container, Row, Col, Image } from "react-bootstrap"
import { FaEnvelope, FaPhone } from "react-icons/fa"
import LightSpeed from "react-reveal/LightSpeed"
import Fade from "react-reveal/Fade"
import "../styles/jobs.css"

import imgAusbildung01 from "../assets/ausbildung.jpg"

export default () => (
  <Container fluid id="ausbildung">
    <Row>
      <Col xs={12} sm={4} className="contentbox contact gray text-center">
        <Fade left>
          <Image src={imgAusbildung01} fluid />
        </Fade>
      </Col>
      <Col xs={12} sm={8} className="contentbox contact white text-center">
        <LightSpeed right>
          <div>
            <h1>Wir bilden aus!</h1>
            <h4>Immer obenauf</h4>
            <hr />
            <div className="jobs-wrapper">
              <div className="jobs-container">
                <h4>Du hast:</h4>
                <ul className="jobs-list">
                  <li>Handwerkliches Geschick</li>
                  <li>Eine gute körperliche Konstitution</li>
                  <li>Spaß daran in der Höhe zu arbeiten</li>
                  <li>Einen Haupt- oder Realschulabschluss</li>
                </ul>
                <p />
                <h4>Wir bieten:</h4>
                <ul className="jobs-list">
                  <li>
                    Ausbildung zum Dachdecker*in in der Fachrichtung Dach-,
                    Wand- und Abdichtungstechnik
                  </li>
                  <li>Einen Beruf mit Ausblick</li>
                  <li>Übernahmechancen</li>
                  <li>Möglichkeit zur Weiterentwicklung im Unternehmen</li>
                </ul>
                <p />
                <h4>Du lernst:</h4>
                <ul className="jobs-list">
                  <li>
                    In 3 Jahren wie man Dächer / Fassaden / Bauwerksabdichtungen
                    errichtet
                  </li>
                  <li>Auf der Baustelle</li>
                  <li>In der Berufsschule in Köln / Geldern</li>
                  <li>In der überbetrieblichen Ausbildungsstätte</li>
                </ul>
                <p />
                <h4>Ausführliche Informationen...</h4>
                <ul className="jobs-list">
                  <li>
                    findest du unter &nbsp;
                    <a
                      className="red-link"
                      href="https://www.dachdeckerdeinberuf.de"
                    >
                      www.dachdeckerdeinberuf.de
                    </a>
                  </li>
                  <li>
                    Wir freuen uns über jeden freiwilligen <b>Praktikant*in</b>
                  </li>
                </ul>
              </div>
            </div>
            <p />
            <Row>
              <Col className="text-left">
                <div className="jobs-wrapper">
                  <div className="jobs-container">
                    <h4>Bewirb dich jetzt mit:</h4>
                    <ul className="jobs-writeus">
                      <li>Deinem Lebenslauf mit Foto</li>
                      <li>Dem aktuellen Schulzeugnis</li>
                      <li>... oder komm einfach vorbei</li>
                    </ul>
                    <a
                      className="red-link"
                      href="mailto:jobs@dachdeckerei-buergel.de"
                    >
                      <FaEnvelope /> jobs@dachdeckerei-buergel.de
                    </a>
                  </div>
                </div>
              </Col>
              <Col className="text-center">
                <table className="contact-table">
                  <thead>
                    <tr>
                      <th>
                        <h4>
                          Dachdeckerei Michael Bürgel e.K. <br />
                          Inhaber Miro Graf
                        </h4>
                      </th>
                    </tr>
                    <tr>
                      <th>
                        <br />
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td> Kruppstr .14 </td>
                    </tr>
                    <tr>
                      <td> 41540 Dormagen </td>
                    </tr>
                    <tr>
                      <td>
                        <a className="black-link" href="tel:0049213363294">
                          <FaPhone className="mirror" /> 02133 / 63294
                        </a>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </Col>
            </Row>
          </div>
        </LightSpeed>
      </Col>
    </Row>
  </Container>
)
